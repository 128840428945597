import React, {useState, useEffect} from 'react';
import ReviewPost from './ReviewPost';
import {getPosts} from '../../actions/posts';
import {HashLink as Link} from 'react-router-hash-link';


function ReviewPage() {
 const [postsData, setPostsData] = useState({});

  useEffect(() => {
    getPosts()
    .then(function (response) {
      setPostsData(response);
    })
    .catch(function (error) {
      console.log(error);
    })
  }, []);

  const reviewExperience = (post) => {
    if (post?.npsScore <3) {
        return post?.disappointedExperience;

    } else if (post?.npsScore ==3) {
        return post?.passiveExperience;
    } else {
        return post?.promoterExperience;
    }
  }

  const patientName =(post) => {
      if (post.anonymous) {
        return "Anonymous"
      } else {
        return (post?.patientNameFirst + " " + post?.patientNameLast)
      }
  }
  

  return (
      
      <div>
          <div className="max-w-8xl mx-auto container bg-white p-10 flex flex-col justify-center items-center">
        <div role="contentinfo" className="flex items-center flex-col px-4">
          <p className="focus:outline-none uppercase text-sm mt-10 text-center text-gray-600 leading-4">
            What Patients Say
          </p>
          <h1 className="focus:outline-none text-4xl lg:text-4xl font-bold text-center leading-10 text-coolGray-800  pt-4 pb-4">
            Reviews
          </h1>
          <Link to='/#Review' className="px-8 py-3 text-lg font-semibold rounded bg-violet-400 text-coolGray-900 hover:bg-coolGray-800 hover:text-white">Leave a Review</Link>
        </div>

        {
        !postsData.length ? "Loading..." : (
          
            <div className="flex-col mt-10">

                {
                    postsData.map((post)=>(
                        <ReviewPost
                            key = {post._id}
                            name = {patientName(post)}
                            verified = {post?.verified}
                            title = {post?.summary}
                            score = {post?.npsScore}
                            features = {post.promoterFeatures}
                            review = {reviewExperience(post)}
                            date= {post.createdAt}
                        
                        />
                    ))
                }
            
            </div>
          )
          } 



      </div>
      </div>
  );
}

export default ReviewPage;
