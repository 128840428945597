import React from "react";
import {HashLink as Link} from 'react-router-hash-link';

import SwiperGrid from "./SwiperGrid";

function Testimonials() {
  return (
    <section className=" bg-violet-100" id='Testimonials'>
      <div className="max-w-8xl mx-auto container bg-violet-100 pt-10 pb-10 flex flex-col justify-center items-center">
          
        <div role="contentinfo" className="flex items-center flex-col px-4">
          <p
            className="focus:outline-none uppercase text-sm mt-10 text-center text-gray-600 leading-4"
          >
            What patients say about my care
          </p>
          <h1
            className="focus:outline-none text-4xl lg:text-4xl font-bold text-center leading-10 text-coolGray-800  pt-4 pb-10"
          >
            Testimonials
          </h1>
        </div>


          <SwiperGrid />
   

        <div className="pt-10 flex">
        <Link to='/reviews' className="flex items-center px-6 py-2 text-sm font-medium text-center rounded bg-violet-400 text-coolGray-900 hover:bg-coolGray-800 hover:text-white">
            Read More
            <svg xmlns="http://www.w3.org/2000/svg" className="w-6 h-6 ml-2 mt-0.5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M17 8l4 4m0 0l-4 4m4-4H3" />
            </svg>
            </Link>

        </div>


      </div>
    </section>
  );
}

export default Testimonials;
