import React, { useRef, useState } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/grid";
import "swiper/css/pagination";

import "../../index.css";

// import required modules
import { Grid, Navigation, Pagination } from "swiper";

export default function SwiperGrid() {
  return (
    <div className="container p-16 flex flex-wrap">
    <Swiper
      // install Swiper modules
      modules={[Pagination,Grid, Navigation]}
      spaceBetween={10}
      slidesPerView={1}
      navigation={true}
      pagination={{ clickable: true }}
      breakpoints={{
        640: {
          slidesPerView: 1,
          spaceBetween: 20,
        },
        768: {
          slidesPerView: 2,
          spaceBetween: 40,
        },
        1024: {
          slidesPerView: 3,
          spaceBetween: 50,
        },
      }}
      // onSwiper={(swiper) => console.log(swiper)}
      // onSlideChange={() => console.log("slide change")}
      className="mySwiper"
    >
      <div className='mb-10'>

      <SwiperSlide>
        <div className="p-4 text-gray-800 rounded-lg shadow-lg bg-coolGray-100">
          <div className="mb-2">
            <p className="mb-2 text-center text-gray-600 ">
              " I don't know how to thank you for YOU! Your Optimistic Wonderful Spirit. I can't thank you enough , for saving my life and curing me of two different cancers. You are an amazing person and Doctor! "
            </p>
            <div className="flex flex-col items-center justify-center">
              {/* <div className="w-12 h-12 overflow-hidden bg-gray-100 border-2 border-indigo-100 rounded-full">
                <img
                  src="https://cdn.pixabay.com/photo/2017/05/19/12/38/entrepreneur-2326419__340.jpg"
                  alt="img"
                  className="object-cover object-center w-full h-full"
                />
              </div> */}
              <h5 className="font-bold text-indigo-600">Anonymous</h5>
              <p className="text-sm text-gray-600">Patient</p>
            </div>
          </div>
        </div>
      </SwiperSlide>
      <SwiperSlide>
        <div className="p-4 text-gray-800 rounded-lg shadow-lg bg-coolGray-100">
          <div className="mb-2">
            <p className="mb-2 text-center text-gray-600 ">
              " I am well aware that in all likelihood I will never be completely out of the woods but thanks to you, I know that I can and will continue to love my life and fight whatever battles come my way with the hope and resolve that you've given me. "
            </p>
            <div className="flex flex-col items-center justify-center">
              {/* <div className="w-12 h-12 overflow-hidden bg-gray-100 border-2 border-indigo-100 rounded-full">
                <img
                  src="https://cdn.pixabay.com/photo/2017/05/19/12/38/entrepreneur-2326419__340.jpg"
                  alt="img"
                  className="object-cover object-center w-full h-full"
                />
              </div> */}
              <h5 className="font-bold text-indigo-600">Anonymous</h5>
              <p className="text-sm text-gray-600">Patient</p>
            </div>
          </div>
        </div>
      </SwiperSlide>
      <SwiperSlide>
        <div className="p-4 text-gray-800 rounded-lg shadow-lg bg-coolGray-100">
          <div className="mb-2">
            <p className="mb-2 text-center text-gray-600 ">
              " Any healthcare provider can give medical advice, but Dr. Sara does it with compassion, genuine care, and a smile that gives me hope. "
            </p>
            <div className="flex flex-col items-center justify-center">
              {/* <div className="w-12 h-12 overflow-hidden bg-gray-100 border-2 border-indigo-100 rounded-full">
                <img
                  src="https://cdn.pixabay.com/photo/2017/05/19/12/38/entrepreneur-2326419__340.jpg"
                  alt="img"
                  className="object-cover object-center w-full h-full"
                />
              </div> */}
              <h5 className="font-bold text-indigo-600">Anonymous</h5>
              <p className="text-sm text-gray-600">Patient</p>
            </div>
          </div>
        </div>
      </SwiperSlide>
      <SwiperSlide>
        <div className="p-4 text-gray-800 rounded-lg shadow-lg bg-coolGray-100">
          <div className="mb-2">
            <p className="mb-2 text-center text-gray-600 ">
              "No beauty shines brighter than a good heart, like yours. Thank you for your outstanding care this past year and a half. I am so fortunate to have you in my corner. "
            </p>
            <div className="flex flex-col items-center justify-center">
              {/* <div className="w-12 h-12 overflow-hidden bg-gray-100 border-2 border-indigo-100 rounded-full">
                <img
                  src="https://cdn.pixabay.com/photo/2017/05/19/12/38/entrepreneur-2326419__340.jpg"
                  alt="img"
                  className="object-cover object-center w-full h-full"
                />
              </div> */}
              <h5 className="font-bold text-indigo-600">Anonymous</h5>
              <p className="text-sm text-gray-600">Patient</p>
            </div>
          </div>
        </div>
      </SwiperSlide>
      <SwiperSlide>
        <div className="p-4 text-gray-800 rounded-lg shadow-lg bg-coolGray-100">
          <div className="mb-2">
            <p className="mb-2 text-center text-gray-600 ">
            We don't know when or how we will leave this earth. I want to thank you for the love and compassion you showed my friend and his family. It is not often to find a Doctor who reaches out and shows true love and care to patients in what might be a pretty hopeless situation. My friend would always mention how much he appreciated all you did for him.
            </p>
            <div className="flex flex-col items-center justify-center">
              {/* <div className="w-12 h-12 overflow-hidden bg-gray-100 border-2 border-indigo-100 rounded-full">
                <img
                  src="https://cdn.pixabay.com/photo/2017/05/19/12/38/entrepreneur-2326419__340.jpg"
                  alt="img"
                  className="object-cover object-center w-full h-full"
                />
              </div> */}
              <h5 className="font-bold text-indigo-600">Anonymous</h5>
              <p className="text-sm text-gray-600">Patient</p>
            </div>
          </div>
        </div>
      </SwiperSlide>
      <SwiperSlide>
        <div className="p-4 text-gray-800 rounded-lg shadow-lg bg-coolGray-100">
          <div className="mb-2">
            <p className="mb-2 text-center text-gray-600 ">
            Without making promises, you approached my treatment with honesty and compassion. You gave me HOPE when I was confused and frightened. You are truly an angel on earth and I'm blessed to have you as my oncologist
            </p>
            <div className="flex flex-col items-center justify-center">
              {/* <div className="w-12 h-12 overflow-hidden bg-gray-100 border-2 border-indigo-100 rounded-full">
                <img
                  src="https://cdn.pixabay.com/photo/2017/05/19/12/38/entrepreneur-2326419__340.jpg"
                  alt="img"
                  className="object-cover object-center w-full h-full"
                />
              </div> */}
              <h5 className="font-bold text-indigo-600">Anonymous</h5>
              <p className="text-sm text-gray-600">Patient</p>
            </div>
          </div>
        </div>
      </SwiperSlide>
      

      </div>


    </Swiper>
    </div>
  );
}
