import React, { Component } from "react";


import * as Survey from "survey-react";



// import "survey-react/modern.css";
import "survey-react/modern.css";

import "../../index.css";


var defaultThemeColors = Survey
    .StylesManager
    .ThemeColors["modern"];

defaultThemeColors["$main-color"] = "rgb(167 139 250)";
defaultThemeColors["$main-hover-color"] = "rgb(167 139 250)";
// defaultThemeColors["$text-color"] = "#898bfa";
defaultThemeColors["$header-color"] = "rgb(167 139 250)";

defaultThemeColors["$header-background-color"] = "rgb(167 139 250)";
// defaultThemeColors["$body-container-background-color"] = "rgb(167 139 250)";
// defaultThemeColors["$inputs-background-color"] = "#898bfa";

Survey
    .StylesManager
    .applyTheme();


Survey.StylesManager.applyTheme("modern");


class SurveyForm extends Component {
    constructor() {
        super();
        
    }
    render() {
        

        

        const json = {
 "completedHtml": "<h3>Thank you for your feedback.</h3> <h5>Your thoughts and ideas will help us to create a better care experience!</h5>",
 "completedHtmlOnCondition": [
  {
   "expression": "{npsScore} > 3",
   "html": "<h3>Thank you for your feedback.</h3> <h5>We glad that you loved your care experience. Your ideas and suggestions will help us to make our services even better!</h5>"
  },
  {
    "expression": "{npsScore} < 3",
    "html": "<h3>Thank you for your feedback.</h3> <h5> We are glad that you shared with us your ideas. We highly value all suggestions from our patients. We will do our best to improve the healthcare experience and reach your expectations.</h5><br/>"
  }
 ],
 "pages": [

    {
        "name": "Consent",
        "title": "Consent",
        "elements": [
            {
                    "type": "boolean",
                    "name": "shareReview",
                    "title": "Share Review",
                    "description": "If Yes, we share your feedback on our Reviews page. If No, your feedback is shared privately with Dr. Matar's team.",
                    "label": "Do you consent to publish your review on our website?",
                    "isRequired": true            
            },
            {
                "type": "boolean",
                "name": "shareName",
                "title": "Share Name",
                "visibleIf": "{shareReview} = true",
                "description": "If Yes, we share your name in addition to your feedback on our Reviews page. If No, we share your feedback only on our Reviews page.",
                "label": "Do you consent to publish your name on our website?",
                "isRequired": true            

            },
            {
                "type": "boolean",
                "name": "anonymous",
                "title": "anonymous",
                "description": "If Yes, your anonymous feedback is private with Dr Matar's team. If No, your feedback and name is shared only with Dr Matar's team.",
                "visibleIf": "{shareReview} = false",
                "label": "Do you wish to make an anonymous review?",
                "isRequired": true            

            },

            
        ]
    

    },

    {
    "name": "About",
    "title": "About you",
    "visibleIf": "{anonymous}= false || {shareReview} = true ",
    "elements": [
        {
            "type": "panel",
            "name": "patienName",
            "elements": [
                {
                    "type": "text",
                    "name": "patientNameLast",
                    "title": "(Last)",
                    "isRequired": true
                }, {
                    "type": "text",
                    "name": "patientNameFirst",
                    "startWithNewLine": false,
                    "title": "(First)",
                    "isRequired": true
                }, {
                    "type": "text",
                    "name": "patientNameMiddle",
                    "startWithNewLine": false,
                    "title": "(M.I)"
                }
            ],
            "questionTitleLocation": "bottom",
            "title": "Patient Name"
        }, {
            "type": "panel",
            "name": "panel2",
            "elements": [
                {
                    "name": "email",
                    "type": "text",
                    "inputType": "email",
                    "title": "E-mail:",
                    "placeHolder": "jon.snow@nightwatch.org",
                    "isRequired": true,
                    "autoComplete": "email",
                    "validators": [
                        {
                            "type": "email"
                        },
                    ]
                },
                
                {
                    "type": "text",
                    "inputType": "date",
                    "isRequired": true,
                    "name": "birthDate",
                    "startWithNewLine": false,
                    "title": "Date of birth:"
                }, {
                    "type": "radiogroup",
                    "choices": [
                        {
                            "value": "male",
                            "text": "Male"
                        }, {
                            "value": "female",
                            "text": "Female"
                        }
                    ],
                    "colCount": 0,
                    "isRequired": true,
                    "name": "sex",
                    "title": "Sex:"
                }
            ],
            "questionTitleLocation": "left",
            "title": "Email & Birth Date"
        }, {
            "type": "panel",
            "name": "panel1",
            "elements": [
                {
                    "type": "radiogroup",
                    "choices": [
                        {
                            "value": "patient",
                            "text": "Patient"
                        }, {
                            "value": "spouse",
                            "text": "Spouse"
                        }
                    ],
                    "colCount": 0,
                    "hasOther": true,
                    "isRequired": true,
                    "name": "completedBy",
                    "otherText": "Other (specify)",
                    "title": "Who completed this form:"
                }, {
                    "type": "text",
                    "name": "completedByOtherName",
                    "visibleIf": "{completedBy} != \"patient\"",
                    "startWithNewLine": false,
                    "title": "Name (if other than patient):",
                    "isRequired": true
                }
            ],
            "title": "Completed By"
        }
    ]
    },
    
    {
   "name": "Review",
   "title": "Review",
   "elements": [
    {
     "type": "rating",
     "name": "npsScore",
     "title": "On a scale of one to five, how likely are you to recommend my practice to a friend or colleague?",
     "isRequired": true,
     "rateMin": 1,
     "rateMax": 5,
     "minRateDescription": "(Most unlikely)",
     "maxRateDescription": "(Most likely)"
    },
    {
     "type": "checkbox",
     "name": "promoterFeatures",
     "visibleIf": "{npsScore} >= 4",
     "title": "What did you value the most?",
     "isRequired": true,
     "validators": [
      {
       "type": "answercount",
       "text": "Please select as many as you like.",
    //    "maxCount": 2
      }
     ],
     "hasOther": true,
     "choices": [
        "Felt heard and empowered",
        "Felt included in decision making",
        "My visit was not rushed",
        "Dr. Matar explained things to a level I could understand ",
        "Dr. Matar was able to answer my questions",
        "Dr. Matar is knowledgable and up todate",
        "Dr. Matar is friendly, compassionate and caring ",
        "I trust Dr. Matar with my medical care",
        "Dr. Matar provided resources ( SW, dietician, support group info, websites, clinical trial info)",
        "Dr. Matar educated me about my treatment plan",
        "Dr. Matar treated me as a whole person and prioritized my goals"
     ],
     "otherText": "Other:",
     "colCount": 2
    },
    {
        "type": "text",
        "name": "summary",
        "title": "A short one sentence title of your experience",
        "visibleIf": "{npsScore} >0",
        "isRequired": true,
       },
    {
        "type": "comment",
        "name": "promoterExperience",
        "visibleIf": "{npsScore} >= 4",
        "title": "Tell us what you liked about your patient experience?",
        "isRequired": true,
       },
    {
     "type": "comment",
     "name": "passiveExperience",
     "visibleIf": "{npsScore} = 3",
     "title": "What is the primary reason for your score?",
     "isRequired": true,
    },
    {
     "type": "comment",
     "name": "disappointedExperience",
     "visibleIf": "{npsScore} <3",
     "title": "What was disappointing in your experience with us?",
     "isRequired": true,
    }
   ]


  }
 ],
 "showQuestionNumbers": "off"
}
;
const survey = new Survey.Model(json);

survey.onComplete.add(function (sender, options) {
    //Show message about "Saving..." the results
    // options.showDataSaving();//you may pass a text parameter to show your own text
    var xhr = new XMLHttpRequest();
    xhr.open("POST", "https://saramatar.herokuapp.com/posts");
    xhr.setRequestHeader("Content-Type", "application/json; charset=utf-8");
    xhr.onload = xhr.onerror = function () {
        if (xhr.status === 200) {
            // options.showDataSavingSuccess(); // you may pass a text parameter to show your own text
            // Or you may clear all messages:
            // options.showDataSavingClear();
            console.log('post saved successfuly.');
        } else {
            //Error
            // options.showDataSavingError(); // you may pass a text parameter to show your own text
            console.log('could not save post.');
        }
    };
    xhr.send(JSON.stringify(sender.data));
});        

// survey
//     .onUpdateQuestionCssClasses
//     .add(function (survey, options) {
//         var classes = options.cssClasses

//         classes.root = "sv-root";
//         classes.title = "sq-title";
//         classes.title = 'sv-question__title--answer';

//         // classes.item = "sq-item";
//         // classes.label = "sq-label";
//         // classes.button = "sv-btn";
//         classes.checkbox = 'sv-checkbox';


//         if (options.question.isRequired) {
//             classes.title += " sq-title-required";
//             classes.root += " sq-root-required";
//         }

//         if (options.question.getType() === "checkbox") {
//             classes.root += " sq-root-cb";
//         }
//     });



        return (
            <Survey.Survey
                model={survey}
            />

            
                


        );
    }
}

export default SurveyForm;
