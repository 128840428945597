import React from "react";
import { Timeline } from "react-twitter-widgets";

function News() {
  return (
    <section className=" bg-white" id="News">
      <div className="max-w-8xl mx-auto container bg-white pt-10 pb-10 flex flex-col justify-center items-center">
        <div role="contentinfo" className="flex items-center flex-col px-4">
          <p className="focus:outline-none uppercase text-sm mt-10 text-center text-gray-600 leading-4">
            What am I up to
          </p>
          <h1 className="focus:outline-none text-4xl lg:text-4xl font-bold text-center leading-10 text-coolGray-800  pt-4 pb-4">
            News
          </h1>
        </div>

        <div className="flex container justify-around">
          <div className="flex p-2 ml-20">{ProfileSmaller()}</div>
          
          <div className="flex p-2">
                        
             </div>

          {/* <div className="flex flex-col justify-between items-start p-2 mr-10 ">
            <div> 
              <div className="text-base lg:text-xl font-semibold text-coolGray-700 p-2 ">
                My Latest Valley Oncology Stories
              </div>
              <div className="border-t border-warmGray-300 ">
                Coming Soon...

                <div className="pt-10 flex">
              
                  <a
                    target="_blank"
                    href="https://www.getrevue.co/profile/saramatar"
                    className="flex items-center mb-6 px-6 py-2 text-sm font-medium text-center rounded bg-violet-400 text-coolGray-900 hover:bg-coolGray-800 hover:text-white"
                  >
                    Read More
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="w-6 h-6 ml-2 mt-0.5"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M17 8l4 4m0 0l-4 4m4-4H3"
                      />
                    </svg>
                  </a>
                </div>

              </div>
            </div>
            
            <div className='italic'>
              Would like to stay up to date with my stories? <a target="_blank" href="https://www.getrevue.co/profile/saramatar" className='underline decoration-pink-400 font-semibold hover:font-bold hover:underline-offset-2'>Subscribe Here</a>
            </div>
          </div> */}

          
        </div>
      </div>
    </section>
  );
}

export default News;

export const ProfileSmaller = () => (
  <Timeline
    dataSource={{ sourceType: "profile", screenName: "saramatarmd" }}
    options={{ width: "400", height: "600" }}
  />
);

export const ProfileBasic = () => (
  <Timeline dataSource={{ sourceType: "profile", screenName: "reactjs" }} />
);
