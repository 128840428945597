import React from "react";

function ExperiencePage() {
  return (
    <div>
      <div className="max-w-8xl mx-auto container bg-white p-10 flex flex-col justify-center items-center">
        <div role="contentinfo" className="flex items-center flex-col px-4">
          <p className="focus:outline-none uppercase text-sm mt-10 text-center text-gray-600 leading-4">
            A bit more about me
          </p>
          <h1 className="focus:outline-none text-4xl lg:text-4xl font-bold text-center leading-10 text-coolGray-800  pt-4 pb-4">
            Experience
          </h1>
        </div>

        <div>
          <div>
            <h2 className="uppercase text-2xl font-bold">
              Professional Experience
            </h2>
            <ul className="marker:text-pink-200 list-disc pl-5 space-y-3 text-slate-500">
            <li>
              Attending,Hematology Oncology{" "}
              <span className="font-bold">
                @Hematology Oncology Associates, Medford, Oregon{" "}
              </span>{" "}
              ——— 2020 to present
            </li>
            <li>
              Fellow Physician,Hematology Oncology{" "}
              <span className="font-bold">
                @Medical University of South Carolina{" "}
              </span>{" "}
              ——— 2017 to 2020
            </li>
            <li>
              Resident Physician,Internal Medicine{" "}
              <span className="font-bold">
                @Medical University of South Carolina{" "}
              </span>{" "}
              ——— 2014 to 2017
            </li>
            <li>
              Preliminary Year, Internal Medicine{" "}
              <span className="font-bold">@American University of Beirut </span>{" "}
              ——— 2013 to 2014
            </li>
            <li>
              Sub Internship,Gastroenterology{" "}
              <span className="font-bold">
                @UCLA Ronald Reagan Medical Center{" "}
              </span>{" "}
              ——— 2013
            </li>
            <li>
              Sub Internship,Hematology Oncology{" "}
              <span className="font-bold">@Indiana University Hospital </span>{" "}
              ——— 2013
            </li>
            </ul>
            <br />
          </div>

          <div>

          <h2 className="uppercase text-2xl font-bold">Service, Awards, Certifications</h2>

          <ul className="marker:text-pink-200 list-disc pl-5 space-y-3 text-slate-500">
                      <li>
                      Chair of Asante Cancer Center Committe.
                      </li>
                      <li>
                      Alpha Omega Alpha Medical Honor Society.
                      </li>
                      <li>
                      First Prize for Outstanding Research at Department of Medicine Research Symposium at MUSC.
                      </li>
                      <li>
                      FDA Scholarship Award for Fellows Day Workshop.
                      </li>
                      <li>
                      Chosen by her peers as the best resident Scholar at MUSC.                     
                      </li>
                  </ul>

          </div>
<br/>
          <div>
            <h2 className="uppercase text-2xl font-bold">Publications</h2>
            <br />
            <h3 className="uppercase text-xl"> In Press</h3>
<ul className="marker:text-pink-200 list-disc pl-5 space-y-3 text-slate-500">

            <li>
              "Pegfilgrastim 6 mg versus 12 mg for Autologous Stem Cell
              Mobilization in Multiple Myeloma Patients: Efficacy, Safety, and
              Cost Analysis." Abstract published in Blood 126.23
              (2015):4306-4306.
            </li>
            <li>
              Advances in immunotherapy for melanoma management. Human Vaccines
              and Immunotherapeutics. 2016 July 25; 1-11. PubMed PMID: 27454404
            </li>
            <li>
              mTORC2-Akt signaling axis is implicated in myocardial compensation
              and fibrosis. Journal of Biological Regulators And Homeostatic
              Agents. 2015 Oct­Dec;29 (4):745-53. PubMed PMID: 26753634.
            </li>
            <li>
              Parvovirus B19-induced Hemophagocytic Lymphohistocytosis: Case
              Report and Review of the Literature. Clinical Case Reports. 2019
              Sept 27. Pubmed PMID: 31788255
            </li>
            <li>
              Clinical impact of COVID-19 on patients with cancer (CCC19): a
              cohort study. The LANCET. 2020 May 28. Pubmed PMID: 32473681
            </li>
            </ul>
            <br />
            <h3 className="uppercase text-xl">Oral Presentations</h3>
            <ul className="marker:text-pink-200 list-disc pl-5 space-y-3 text-slate-500">
            <li>
              Patient Safety Indicators at the American College of Medical
              Quality MUSC Chapter, MUSC 2018
            </li>
            <li>
              My Sick Thyroid Got Me Weak in the Knees, Society of General
              Internal Medicine 2016
            </li>
            <li>
              Pegfilgrastim 6 mg versus 12 mg for Autologous Stem Cell
              Mobilization in Multiple Myeloma Patients: Efficacy, Safety, and
              Cost Analysis, American Society of Hematology 2015
            </li>
            <li>
              “Doc, fix me. I am swelling up like a balloon!”: A Bewildering
              Case of Anasarca, American College of Physicians 2015
            </li>
            <li>
              Pulmonary Veno-Occlussive Disease (PVOD), a rare condition you
              wouldn’t want to misdiagnose or mistreat, American College of
              Physicians 2014
            </li>
            <li>
              How to prepare for USMLE and apply to the MATCH, Lebanese Medical
              Students’ International Committee Guest Speaker, AUB 2014
            </li>
            <li>
              Molecular genetic analysis in a Lebanese family with
              Atrioventricular Nodal Reentry Tachycardia (AVNRT), Research Day
              at AUB 2013
            </li>
</ul>
          </div>

          <br />
          <div>
            <h2 className="uppercase text-2xl font-bold">
              Certifications and Licensure
            </h2>
            <ul className="marker:text-pink-200 list-disc pl-5 space-y-3 text-slate-500">
              <li>ABIM Medical Oncology Board Certification</li>
              <li>ABIM Internal Medicine Board Certification</li>
              <li>ECFMG certification</li>
              <li>Optune™️ GlioBlastoma Multiforme Certified</li>
              <li>Licensed to practice in the State of Oregon</li>
              <li>Approved Clinical Trials Investigator with SWOG</li>
            </ul>
          </div>
          <br />
          <div>
            <h2 className="uppercase text-2xl font-bold">
              Membership in Medical Societies
            </h2>
            <ul className="marker:text-pink-200 list-disc pl-5 space-y-3 text-slate-500">
              <li>Southwest Oncology Group (SWOG)</li>
              <li>American Society of Clinical Oncology (ASCO)</li>
              <li>American Society of Hematology (ASH)</li>
              <li>Hemostasis and Thrombosis Research Society (HTRS)</li>
              <li>Commission on Cancer - American College of Surgeons (COC)</li>
              <li>Oregon Society of Medical Oncology (OSMO)</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ExperiencePage;
