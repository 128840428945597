import React from 'react';
import Logo from '../Logo';
import {HashLink as Link} from 'react-router-hash-link';
import Burger from './Burger';


function Nav() {

  return (
  <header className="p-4 bg-coolGray-800 text-coolGray-100" id='Home'>
	<div className="container flex justify-between h-16 mx-auto">
		<div className="flex">
			<Link to="/#Home" aria-label="Back to homepage" className="flex items-center p-2">
                <Logo />
			</Link>
			<ul className="items-stretch hidden space-x-3 sm:flex">
				<li className="flex">
				<Link to="/#About" className="flex items-center font-semibold px-4 -mb-1 border-b-2 border-transparent   hover:font-bold hover:border-pink-400 hover:text-pink-300">Practice</Link>
				</li>
				<li className="flex">
				<Link to="/#Testimonials" className="flex items-center font-semibold px-4 -mb-1 border-b-2 border-transparent   hover:font-bold hover:border-pink-400 hover:text-pink-300">Testimonials</Link>
				</li>
                <li className="flex">
					<Link to="/#News" className="flex items-center font-semibold px-4 -mb-1 border-b-2 border-transparent   hover:font-bold hover:border-pink-400 hover:text-pink-300">News</Link>
				</li>
				<li className="flex">
					{/* <a rel="noopener noreferrer" href="#" onclick ={openReviews} className="flex items-center font-semibold px-4 -mb-1 border-b-2 border-transparent hover:text-violet-400 hover:border-violet-400">Reviews</a> */}
					<Link to="/experience" className="flex items-center font-semibold px-4 -mb-1 border-b-2 border-transparent   hover:font-bold hover:border-pink-400 hover:text-pink-300">Experience</Link>
				</li>
                <li className="flex">
					{/* <a rel="noopener noreferrer" href="#" onclick ={openReviews} className="flex items-center font-semibold px-4 -mb-1 border-b-2 border-transparent hover:text-violet-400 hover:border-violet-400">Reviews</a> */}
					<Link to="/reviews" className="flex items-center font-semibold px-4 -mb-1 border-b-2 border-transparent   hover:font-bold hover:border-pink-400 hover:text-pink-300">Reviews</Link>
				</li>
                {/* <li className="flex">
					<a rel="noopener noreferrer" href="#" className="flex items-center font-semibold px-4 -mb-1 border-b-2 border-transparent hover:text-violet-400 hover:border-violet-400">Fundraising</a>
				</li> */}
			</ul>

		</div>
		{/* <div className="items-center flex-shrink-0 hidden lg:flex">
			<button className="px-8 py-3 font-semibold rounded bg-violet-400 text-coolGray-900">Log in</button>
		</div> */}
        

		<div className="p-4 lg:hidden">
			
			{/* <button>
				<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" className="w-6 h-6 text-coolGray-100">
					<path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16M4 18h16"></path>
				</svg>
			</button> */}

			{/* <Burger /> */}

		</div>
	</div>
</header>
)

}


export default Nav;