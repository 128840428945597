import React from 'react';
import SurveyForm from './SurveyForm';

function ReviewForm() {
  return <section className=" bg-pink-100" id='Review'>
    <div className="max-w-8xl mx-auto container bg-pink-100 pt-10 pb-10 flex flex-col justify-center items-center">
        
        <div role="contentinfo" className="flex items-center flex-col px-4">
            <p
                className="focus:outline-none uppercase text-sm mt-10 text-center text-gray-600 leading-4"
            >
                Review your patient experience
            </p>
            <h1
                className="focus:outline-none text-4xl lg:text-4xl font-bold text-center leading-10 text-coolGray-800  pt-4 pb-4"
            >
                Review
            </h1>
        </div>

        <SurveyForm />

    </div>


 </section>
}

export default ReviewForm;
