import React, { Component } from 'react';
import {HashLink as Link} from 'react-router-hash-link';
import { slide as Menu } from 'react-burger-menu'

export default class Burger extends Component {
    constructor (props) {
      super(props)
      this.state = {
        menuOpen: false
      }
    }
  
    // This keeps your state in sync with the opening/closing of the menu
    // via the default means, e.g. clicking the X, pressing the ESC key etc.
    handleStateChange (state) {
      this.setState({menuOpen: state.isOpen})  
    }
    
    // This can be used to close the menu, e.g. when a user clicks a menu item
    closeMenu () {
      this.setState({menuOpen: false})
    }
  
    // This can be used to toggle the menu, e.g. when using a custom icon
    // Tip: You probably want to hide either/both default icons if using a custom icon
    // See https://github.com/negomi/react-burger-menu#custom-icons
    toggleMenu () {
      this.setState(state => ({menuOpen: !state.menuOpen}))
    };
  
    

    render () {

        var styles = {
            bmBurgerButton: {
              position: 'fixed',
              width: '36px',
              height: '30px',
              left: '36px',
              top: '36px'
            },
            bmBurgerBars: {
              background: '#373a47'
            },
            bmBurgerBarsHover: {
              background: '#a90000'
            },
            bmCrossButton: {
              height: '24px',
              width: '24px'
            },
            bmCross: {
              background: '#bdc3c7'
            },
            bmMenuWrap: {
              position: 'fixed',
              height: '100%'
            },
            bmMenu: {
              background: '#373a47',
              padding: '2.5em 1.5em 0',
              fontSize: '1.15em'
            },
            bmMorphShape: {
              fill: '#373a47'
            },
            bmItemList: {
              color: '#b8b7ad',
              padding: '0.8em'
            },
            bmItem: {
              display: 'inline-block'
            },
            bmOverlay: {
              background: 'rgba(0, 0, 0, 1)'
            }
          };

      return (
        <div>
          <Menu 
            isOpen={this.state.menuOpen}
            onStateChange={(state) => this.handleStateChange(state)}
            right 
            disableAutoFocus 
            width={ 280 } 
            style={styles}
          >
            <Link onClick={() => this.closeMenu()} to="/#About" className="flex items-center font-semibold px-4 -mb-1 border-b-2 border-transparent   hover:font-bold hover:border-pink-400 hover:text-pink-300">Practice</Link>
            <Link onClick={() => this.closeMenu()} to="/#Testimonials" className="flex items-center font-semibold px-4 -mb-1 border-b-2 border-transparent   hover:font-bold hover:border-pink-400 hover:text-pink-300">Testimonials</Link>
            <Link onClick={() => this.closeMenu()} to="/#News" className="flex items-center font-semibold px-4 -mb-1 border-b-2 border-transparent   hover:font-bold hover:border-pink-400 hover:text-pink-300">News</Link>
            <Link onClick={() => this.closeMenu()} to="/reviews" className="flex items-center font-semibold px-4 -mb-1 border-b-2 border-transparent   hover:font-bold hover:border-pink-400 hover:text-pink-300">Reviews</Link>
        
          </Menu>
          {/* <CustomIcon onClick={() => this.toggleMenu()} /> */}
          <button onClick={() => this.toggleMenu()}>
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" className="w-6 h-6 text-coolGray-100">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16M4 18h16"></path>
            </svg>
        </button>
        </div>
      )
    }
  }