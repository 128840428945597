import React from 'react';
import {HashLink as Link} from 'react-router-hash-link';


const About = () => {
  return <section className="max-w-8xl mx-auto container bg-white pt-10" id='About'>
      <div>
      <div role="contentinfo" className="flex items-center flex-col px-4">
          <p
            className="focus:outline-none uppercase text-sm mt-10 text-center text-gray-600 leading-4"
          >
            What I do
          </p>
          <h1
            className="focus:outline-none text-4xl lg:text-4xl font-bold text-center leading-10 text-coolGray-800  pt-4 pb-4"
          >
            My Practice
          </h1>
     </div>


        <div tabIndex={0} aria-label="group of cards" className="focus:outline-none mt-20 flex flex-wrap justify-center gap-10 px-4">
          <div tabIndex={0} aria-label="card 1" className="focus:outline-none flex sm:w-full md:w-5/12 pb-20">
            <div className="w-20 h-20 relative mr-5">
              <div className="absolute top-0 right-0 bg-violet-100 rounded w-16 h-16 mt-2 mr-1" />
              <div className="absolute text-white bottom-0 left-0 bg-violet-400 rounded w-16 h-16 flex items-center justify-center mt-2 mr-3">
              <img src={require("../svg/clinic.svg").default} alt='background'/>
              </div>
            </div>
            <div className="w-10/12">
              <h2 tabIndex={0} className="focus:outline-none text-lg font-semibold leading-tight text-coolGray-800">Clinic</h2>
              <p tabIndex={0} className="focus:outline-none text-base text-gray-600 leading-normal pt-2">I practice with  <a href='https://www.hoapc.com/' className='underline decoration-pink-400 font-semibold hover:font-bold hover:underline-offset-2'> 
                Hematology Oncology Associates</a> group at <a href='https://www.asante.org/services/cancer-services/locations/heimann-cancer-center/' 
                className='underline decoration-pink-400 font-semibold hover:font-bold hover:underline-offset-2'> Asante Heimann Cancer Center </a> 
                in Medford and <a className='underline decoration-pink-400 font-semibold hover:font-bold hover:underline-offset-2' 
                href='https://www.asante.org/Locations/location-detail/helen-k-spears-cancer-center/'> Spears Cancer Center</a> in Grants Pass Oregon.</p>
            </div>
          </div>

          <div tabIndex={0} aria-label="card 2" className="focus:outline-none flex sm:w-full md:w-5/12 pb-20">
            <div className="w-20 h-20 relative mr-5">
              <div className="absolute top-0 right-0 bg-violet-100 rounded w-16 h-16 mt-2 mr-1" />
              <div className="absolute text-white bottom-0 left-0 bg-violet-400 rounded w-16 h-16 flex items-center justify-center mt-2 mr-3">
                
                <img src={require("../svg/education.svg").default} alt='education'/>
              
              </div>
            </div>
            <div className="w-10/12">
              <h2 tabIndex={0} className="focus:outline-none text-lg font-semibold leading-tight text-coolGray-800">Education</h2>
              <div className="focus:outline-none text-base text-gray-600 leading-normal pt-2">
                  
                  <ul className="marker:text-pink-200 list-disc pl-5 space-y-3 text-slate-500">
                      <li>
                      Hematology Oncology fellowship at the National Cancer Institute designated Hollings Cancer Center in Charleston, South Carolina.
                      </li>
                      <li>
                      Internal Medicine Residency at the Medical University of South Carolina.
                      </li>
                      <li>
                      Medical School at the American University of Beirut.
                      </li>
                  </ul>
                                
              </div>
            </div>
          </div>
          <div tabIndex={0} aria-label="card 3" className="focus:outline-none flex sm:w-full md:w-5/12 pb-20">
            <div className="w-20 h-20 relative mr-5">
              <div className="absolute top-0 right-0 bg-violet-100 rounded w-16 h-16 mt-2 mr-1" />
              <div className="absolute text-white bottom-0 left-0 bg-violet-400 rounded w-16 h-16 flex items-center justify-center mt-2 mr-3">
                <img src={require("../svg/award.svg").default} alt='award'/>

              </div>
            </div>
            <div className="w-10/12">
              <h2 tabIndex={0} className="focus:outline-none text-lg font-semibold leading-tight text-coolGray-800">Service & Awards & Certifications</h2>
              <div tabIndex={0} className="focus:outline-none text-base text-gray-600 leading-normal pt-2">
                  
              <ul className="marker:text-pink-200 list-disc pl-5 space-y-3 text-slate-500">
                      <li>
                      Chair of Asante Cancer Center Committe.
                      </li>
                      <li>
                      Alpha Omega Alpha Medical Honor Society.
                      </li>
                      <li>
                      Optune™️ GlioBlastoma Multiform Certified.
                      </li>
                      <li>
                      FDA Scholarship Award for Fellows Day Workshop
                      </li>
                      <li>
                      Chosen by her peers as the best resident Scholar at MUSC                       
                      </li>
                      <li>
                      Published in peer reviewed articles <a href='/experience' 
                className='underline decoration-pink-400 font-semibold hover:font-bold hover:underline-offset-2'> ( list of publications press here) </a> 
                      </li>
                  </ul>


             </div>
            </div>
          </div>
          <div tabIndex={0} aria-label="card 4" className="focus:outline-none flex sm:w-full md:w-5/12 pb-20">
            <div className="w-20 h-20 relative mr-5">
              <div className="absolute top-0 right-0 bg-violet-100 rounded w-16 h-16 mt-2 mr-1" />
              <div className="absolute text-white bottom-0 left-0 bg-violet-400 rounded w-16 h-16 flex items-center justify-center mt-2 mr-3">
              <img src={require("../svg/care.svg").default} alt='care'/>
              </div>
            </div>
            <div className="w-10/12">
              <h2 tabIndex={0} className="focus:outline-none text-lg font-semibold leading-tight text-coolGray-800">Care Philosophy</h2>
              <p tabIndex={0} className="focus:outline-none text-base text-gray-600 leading-normal pt-2">I feel honored to be given the opportunity to take care of my patients. My goal is to help build a personalized treatment plan that’s focused on their needs, while following the most current therapy guidelines.<br/> I want to help each patient understand their options, and provide them with state-of-the-art care. Together, we focus not only on treating the cancer, but alleviating symptoms and <span className="underline-offset-2 italic font-semibold text-violet-400">healing as a whole person.</span></p>
            </div>
          </div>
        </div>
      </div>
    </section>
};
export default About;