import React from 'react';

function Privacy() {
  return <section className="max-w-8xl mx-auto container bg-white p-10 flex flex-col justify-center items-center" id='privacy'>
        <div role="contentinfo" className="flex items-center flex-col px-4">
          <p className="focus:outline-none uppercase text-sm mt-10 text-center text-gray-600 leading-4">
            Your data
          </p>
          <h1 className="focus:outline-none text-4xl lg:text-4xl font-bold text-center leading-10 text-coolGray-800  pt-4 pb-4">
            Privacy Policy
          </h1>
          </div>

<div className='mx-40'>
<p> Your privacy is important to us. It is Saramatar.com's policy to respect your privacy regarding any information we may collect from you across our website.
<br/><br/>
We only ask for personal information when we truly need it to provide a service to you, such as in the instance you submit a contact form on our website to share your review. We collect it by fair and lawful means, with your knowledge and consent. We also let you know why we’re collecting it and how it will be used.
<br/><br/>
We only retain collected information for as long as necessary to provide you with your requested service. What data we store, we’ll protect within commercially acceptable means to prevent loss and theft, as well as unauthorized access, disclosure, copying, use or modification.
</p>
<br/>
<p>

Though we take as many precautionary measures as possible, data transmission cannot be guaranteed to always be 100% secure. Therefore, transmission of data is at your own risk which is assumed upon entry to the site.

</p>

<p>
<br/>    
We don’t share any personally identifying information publicly or with third-parties, except when required to by law.
</p>

<p> 
Our website may link to external sites that are not operated by us. Please be aware that we have no control over the content and practices of these sites, and cannot accept responsibility or liability for their respective privacy policies.
</p>
<br/>

<p>

Your continued use of our website will be regarded as acceptance of our practices around privacy and personal information. If you have any questions about how we handle user data and personal information, feel free to contact us.
</p>
<br/>
<p>
Out of transparency, here is an exhaustive list of all data-tracking services we currently use: Google Analytics, Revue.
</p>
<br/>

If you have any questions or concerns, or would like to exercise any of these rights, please contact us at drsaramatar@gmail.com.
<br/><br/>
This policy is effective as of 2 February 2022 and was last updated 2 February 2022.

  </div>
  </section>
}

export default Privacy
