import * as api from '../api';

//Action Creators function returning actions


export const getPosts = async () => {
    try {
        const {data} = await api.fetchPosts();
        console.log('ran fetchpost');
        return data;
        // dispatch({type: 'FETCH_ALL', payload:data})
    } catch (error) {
        console.log(error.message)
    }
}

export const createPost = (post) => async (dispatch) => {
    try {
        const {data} = await api.createPost(post);
        dispatch({type: 'CREATE', payload: data})
    } catch (error) {
        console.log(error.message);
    }
}