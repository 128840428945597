import React from 'react'
import {HashLink as Link} from 'react-router-hash-link';


function Hero() {
  return <section className="bg-pink-100 text-darkGray-100" id='Hero'>
	<div className="container flex flex-col justify-center px-6 mx-auto sm:pt-8 lg:pt-18 lg:flex-row lg:justify-between">
		
		<div className="flex flex-col mt-16 sm:ml-12 p-6 text-center rounded-sm lg:max-w-md xl:max-w-lg lg:text-left">
			<h1 className="text-4xl font-bold leading-none sm:text-5xl">Hi, I am 
				<br/><span className="text-pink-400"> Dr. Sara Matar.</span>
                <br/>Your Hematology & Oncology Specialist.
			</h1>
			<p className="mt-6 mb-8 text-lg sm:mb-12">In my patient's words:
				<span className="italic font-light"> "Any healthcare provider can give medical advice, but Dr. Sara does it with compassion, genuine care, and a smile that gives me hope." </span>
                </p>
			<div className="flex flex-col space-y-4 sm:items-center sm:justify-center sm:flex-row sm:space-y-0 sm:space-x-4 lg:justify-start">
				<Link to='/#Review' className="px-8 py-3 text-lg font-semibold rounded bg-violet-400 text-coolGray-900 hover:bg-coolGray-800 hover:text-white">Review</Link>
				<a rel="noopener noreferrer" href="https://www.asante.org/Locations/location-detail/mary-and-dick-heimann-cancer-center/" className="px-8 py-3 text-lg font-semibold  rounded bg-violet-400 text-coolGray-900 hover:bg-coolGray-800 hover:text-white">Appointments</a>
			</div>
		</div>

		<div className="flex items-center justify-center mr-10">
			{/* <img src="./images/Matar.png" alt='Profile'/> */}
			<img src="./images/Sara-hero-600-edit.png" width='400' alt='Profile'/>
			{/* <img src="./images/Sara-hero.png" alt='Profile'/> */}

		</div>

	</div>
</section>

}

export default Hero;


