import React from 'react';
import About from './components/about/About';
import Hero from './components/hero/Hero';
import Nav from './components/nav/Nav';
import News from './components/news/News';
import ReviewForm from './components/review/ReviewForm';
import ReviewPage from './components/review/ReviewPage';
import ExperiencePage from './components/experience/Experience';
import Testimonials from './components/testimonials/Testimonials';
import {Routes, Route, Outlet } from "react-router-dom";
import Footer from './components/footer/Footer';
import Privacy from './components/privacy/Privacy';


function App() {
  return (
      <div>
      <Nav />
      <Routes >
        <Route path='/' element={<Layout/>} />
        <Route path='/reviews' element={<ReviewPage/>}/>
        <Route path='/experience' element={<ExperiencePage/>}/>
        <Route path='/#News' element={<News/>} />
        <Route path='/#About' element={<About/>} />
        <Route path='/#Testimonials' element={<Testimonials/>} />
        <Route path='/#Review' element={<ReviewForm/>} />
        <Route path='/privacy' element={<Privacy/>} />

      </Routes>
      <Footer />
      </div>
  );
}

export default App;


function Layout() {
  return (
    <div>
      {/* A "layout route" is a good place to put markup you want to
          share across all the pages on your site, like navigation. */}
          <Hero />
          <About />
          <Testimonials />
          <News />
          <ReviewForm/>
      <hr />

      {/* An <Outlet> renders whatever child route is currently active,
          so you can think about this <Outlet> as a placeholder for
          the child routes we defined above. */}
      <Outlet />
    </div>
  );
}